import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Section from "../../../components/Section";
import { ReactComponent as BackIcon } from "../../../../assets/icon-back.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icon-cross.svg";
import Text from "../../../components/Text";
import Row from "../../../components/Row";
import { useNavigate } from "react-router-dom";
import Column from "../../../components/Column";
import UploadButton from "../../../components/UploadButton";
import { useReducerContext } from "../../../contexts/ReducerContext";
import { convertBytesToMB } from "../../../utils";
import ACTIONS from "../../../constants/actions";
import { deleteEmploymentConditionSealFile, updateEmploymentConditionSealFile, uploadEmploymentConditionSealFile } from "../../../apis/employmentCondition";

interface EmploymentConditionProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 20px;
`;

const HeadingContainer = styled(Row)`
  align-items: center;
  gap: 20px;
`;

const Heading = styled(Text)`
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
`;

const LocalSection = styled(Section)`
  padding: 30px 30px 60px 30px;
  flex-direction: column;
  gap: 30px;
`;

const SectionHeading = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`;

const StampContainer = styled(Column)`
  gap: 10px;
`;

const FileAttrContainer = styled(Row)`
  gap: 10px;
  align-items: center;
`;

const FileNameAnchor = styled.a`
  color: inherit;
  text-decoration: none;
`;

const FileNameText = styled(Text)`
  color: #017698;
`;

const FileSizeText = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

const UploadItemsContainer = styled(Column)`
  gap: 20px;
`;

const DeleteIcon = styled(CrossIcon)`
  border-radius: 5px;
  cursor: pointer;
  path {
   fill: #5f6368; 
  }

  &:hover {
    background-color: #FFEADB;
    path {
      fill: #FF730D;
    }
  }
`;


/* NOTE: This component is for Employer use only and doesn't need to show in other langauges but Japanese */
const EmploymentCondition: FunctionComponent<EmploymentConditionProps> = () => {
  const { state, dispatch } = useReducerContext();
  const { company, isUploading } = state;
  const navigate = useNavigate();
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB;
  const [sealFileId, setSealFileId] = useState(company?.employmentConditionSealFile?.id ?? "");
  const [sealFileName, setSealFileName] = useState(company?.employmentConditionSealFile?.filename ?? "");
  const [sealFileSize, setSealFileSize] = useState(company?.employmentConditionSealFile?.size ?? 0);
  const [sealUrl, setSealUrl] = useState(company?.employmentConditionSealFile?.url ?? "");


  const onSealFileChange = async (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch({ type: ACTIONS.SET_PROMPT, payload: {
          type: 'warning',
          message: "ファイルのアップロードに失敗しました。５MB以下のファイルを選択してください"
        },
      });
      return;
    }
    
    try {
      dispatch({ type: ACTIONS.START_UPLOADING });
      const ecSealFile = sealFileId
        ? await updateEmploymentConditionSealFile(sealFileId, file)
        : await uploadEmploymentConditionSealFile(company!.id, file);

      setSealFileId(ecSealFile.id);
      setSealFileName(ecSealFile.filename);
      setSealFileSize(ecSealFile.size);
      setSealUrl(ecSealFile.url);

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: {
            ...company,
            employmentConditionSealFile: ecSealFile
          } 
        },
      });

    } catch {
    } finally {
      dispatch({ type: ACTIONS.STOP_UPLOADING });
    }
  };

  const onDeleteClick = async () => {
    try {
      await deleteEmploymentConditionSealFile(sealFileId);
      setSealFileId("");
      setSealFileName("");
      setSealFileSize(0);
      setSealUrl("");

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: {
            ...company,
            employmentConditionSealFile: undefined
          } 
        },
      });
    } catch {
    }
  }


  return (
    <Container>
      <HeadingContainer>
        <BackIcon onClick={() => navigate("/settings/documents")} style={{ cursor: "pointer" }} />
        <Heading>雇用条件書</Heading>
      </HeadingContainer>
      <LocalSection>
        <StampContainer>
          <SectionHeading>捺印</SectionHeading>
          <Text>最大5MBのJPG又はPNGファイルのみ（推奨の大きさ：360 x 360ピクセル）</Text>
          <UploadItemsContainer>
            <UploadButton
              buttonText="ファイルを選択"
              onFileChange={onSealFileChange}
              accept=".jpg, .jpeg, .png"
            />
            { isUploading && 
              <Text>アップロード中...</Text> 
            }
            { !isUploading && sealFileId && 
              <FileAttrContainer>
                <FileNameText>
                  <FileNameAnchor 
                    href={sealUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {sealFileName}
                  </FileNameAnchor>
                </FileNameText>
                <FileSizeText>{convertBytesToMB(sealFileSize)}MB</FileSizeText>
                <DeleteIcon onClick={onDeleteClick} />
              </FileAttrContainer>
            }
          </UploadItemsContainer>
        </StampContainer>
      </LocalSection>
    </Container>
  );
};

export default EmploymentCondition;
