import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import Row from "../../../../../core/components/Row";
import Column from "../../../../../core/components/Column";
import { Column10, Column5 } from "../../commonStyles";
import DescriptionField from "../../components/DescriptionField";
import { Section } from "./styles";
import { MOBILE_DEVICE } from "../../../../../core/constants/styles";
import { getYear, getMonth, getDay } from "../../util";
import { TemplateSectionProps } from "../../types";
import { Trans, useTranslation } from "react-i18next";
import SealContainer from "../../components/SealContainer";

interface EmployeeEmployerInfoSectionProps extends TemplateSectionProps {}

const DateBlock = styled.div`
  width: 100%;
  margin-left: auto;
`;

const DateWordsContainer = styled(Row)`
  justify-content: flex-end;
  gap: 5px;
`;

const CandidateNameBlock = styled.div``;

const CandidateNameContainer = styled(Row)`
  gap: 10px;
`;

const EmployerInfoBlockContainer = styled(Column)`
  gap: 10px;
  width: 100%;
  padding-left: 200px;

  @media ${MOBILE_DEVICE} {
    padding-left: 0px;
  }

  @media print {
    padding-left: 200px;  
  }
`;

const RepresentativeFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  align-items: end;
  width: 100%;
  gap: 10px;
  grid-template-areas:
    "field1 seal"
    "field2 seal";
`;

const LocalSealContainer = styled(SealContainer)`
  grid-area: seal;
`;

const EmployeeEmployerInfoSection: FunctionComponent<EmployeeEmployerInfoSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  i18nFormPrefixKey,
  company
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang };
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const createdAt = ec?.createdAt || "";
  const [candidateName, setCandidateName] = useState<string>("");
  const [orgNameInJp, setOrgNameInJp] = useState<string>("");
  const [orgNameInEn, setOrgNameInEn] = useState<string>("");
  const [orgAddressInJp, setOrgAddressInJp] = useState<string>("");
  const [orgAddressInEn, setOrgAddressInEn] = useState<string>("");
  const [orgPhoneNumber, setOrgPhoneNumber] = useState<string>("");
  const [orgRepresentativeTitle, setOrgRepresentativeTitle] = useState<string>("");
  const [orgRepresentativeTitleTrans, setOrgRepresentativeTitleTrans] = useState<string>("");
  const [orgRepresentativeNameInJp, setOrgRepresentativeNameInJp] = useState<string>("");
  const [orgRepresentativeNameInEn, setOrgRepresentativeNameInEn] = useState<string>("");

  useEffect(() => {
    if (!ec) 
      return;

    setCandidateName(
      `${ec.employeeEnFamilyName} ${ec.employeeEnGivenName} ${ec.employeeEnMiddleName}`.trim()
    );
    setOrgNameInJp(ec.employerJaOrganizationName);
    setOrgNameInEn(ec.employerEnOrganizationName);
    setOrgAddressInJp([
      ec.employerJaPrefecture,
      ec.employerJaCityWard,
      ec.employerJaTown,
      ec.employerJaAddressNumber,
      ec.employerJaBuilding
    ].filter(v => v).join(" "));
    setOrgAddressInEn([
      ec.employerEnPrefecture,
      ec.employerEnCityWard,
      ec.employerEnTown,
      ec.employerEnAddressNumber,
      ec.employerEnBuilding
    ].filter(v => v).join(" "));

    setOrgPhoneNumber(ec.employerPhoneNumber);
    setOrgRepresentativeTitle(ec.employerRepresentativeTitle);
    setOrgRepresentativeTitleTrans(transHandler.getTranslation('employerRepresentativeTitle'));
    setOrgRepresentativeNameInJp(ec.employerJaRepresentativeName);
    setOrgRepresentativeNameInEn(ec.employerEnRepresentativeName);
  }, [ec]);



  return (
    <Section>
      <Column10>
        <DateBlock>
          <DateWordsContainer>
            {getYear(createdAt)} 年 {getMonth(createdAt)} 月{" "}
            {getDay(createdAt)} 日
          </DateWordsContainer>
          <DateWordsContainer>
            <Trans
              tOptions={tOptions}
              i18nKey={getTKey("form_created_date")}
              values={{
                year: getYear(createdAt),
                month: getMonth(createdAt),
                day: getDay(createdAt)
              }}
            />
          </DateWordsContainer>
        </DateBlock>
        <CandidateNameBlock>
          <CandidateNameContainer>
            {candidateName && <span>{candidateName}</span>}
            <span>殿</span>
          </CandidateNameContainer>
          <CandidateNameContainer>
            { candidateName &&
              <Trans
                tOptions={tOptions}
                i18nKey={getTKey("candidate_name")}
                values = {{
                  name: candidateName
                }}
                components={{
                  name: <span/>,
                  honorific: <span/>
                 }}
              />
            }
          </CandidateNameContainer>
        </CandidateNameBlock>

        <EmployerInfoBlockContainer>
          <Column5>
            <DescriptionField
              label={"特定技能所属機関名"}
              value={orgNameInJp}
            />
            <DescriptionField
              label={tFixed("spec_skilled_org_name")}
              value={orgNameInEn}
            />
          </Column5>

          <Column5>
            <DescriptionField label={"所在地"} value={orgAddressInJp} />
            <DescriptionField label={tFixed("org_location")} value={orgAddressInEn} />
          </Column5>

          <Column5>
            <DescriptionField label={"電話番号"} value={orgPhoneNumber} />
            <DescriptionField label={tFixed("phone_number")} value={orgPhoneNumber} />
          </Column5>

          <Column5>
            <RepresentativeFieldsContainer>
              <DescriptionField
                label={"代表者役職"}
                value={orgRepresentativeTitle}
              />
              <DescriptionField
                label={"代表者氏名"}
                value={orgRepresentativeNameInJp}
                style={{ gridArea: "field1" }}
              />
              <LocalSealContainer 
                sealImageURL={company?.employmentConditionSealFile?.url}
              />
            </RepresentativeFieldsContainer>

            <DescriptionField
              label={tFixed("representative_title")}
              value={orgRepresentativeTitleTrans}
              style={{ gridArea: "field2" }}
            />
            <DescriptionField
              label={tFixed("representative_name")}
              value={orgRepresentativeNameInEn}
            />
          </Column5>
        </EmployerInfoBlockContainer>
      </Column10>
    </Section>
  );
};

export default EmployeeEmployerInfoSection;
