import { apiUtil } from '../utils';
import { EmploymentConditionSealFile } from '../types/api';

export const uploadEmploymentConditionSealFile = async (companyId: number | string, file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', companyId.toString());
    const response = await apiUtil.post(
        `/employment_condition_seal_files`,
        formData,
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as EmploymentConditionSealFile;
    }
    throw new Error('API error');
}

export const updateEmploymentConditionSealFile = async (sealFileId: number | string, sealFile: File) => {
    const formData = new FormData();
    formData.append('file', sealFile);
    const response = await apiUtil.patch(
        `/employment_condition_seal_files/${sealFileId}`,
        formData,
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as EmploymentConditionSealFile;
    }
    throw new Error('API error');
}

export const deleteEmploymentConditionSealFile = async (sealFileId: number | string) => {
    const response = await apiUtil.delete(
        `/employment_condition_seal_files/${sealFileId}`,
    );

    if (response.status >= 200 && response.status < 300) {
        return response.data as EmploymentConditionSealFile;
    }
    throw new Error('API error');
}

