import { FunctionComponent, HTMLAttributes } from "react"
import styled from "styled-components";
import Text from "../../../../core/components/Text";

interface SealContainerProps extends HTMLAttributes<HTMLDivElement> {
  sealImageURL?: string | null;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 0.5px solid #444444;
`;

const SealText = styled(Text)`
  font-size: 20px;
  color: #999999;
`;

const SealImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SealContainer: FunctionComponent<SealContainerProps> = ({
  sealImageURL,
  ...props
}) => {
  return (
    <Container {...props}>
      {sealImageURL 
        ? <SealImage src={sealImageURL} alt="seal"/> 
        : <SealText>㊞</SealText>
      }
    </Container>
  )
}

export default SealContainer;