import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Column from '../components/Column';
import Page from '../components/Page';
import PageHeading from '../components/PageHeading';
import Section from '../components/Section';
import SubCategoryHeading from '../components/SubCategoryHeading';
import Text from '../components/Text';
import TextInput from '../components/TextInput';
import { MOBILE_DEVICE } from '../constants/styles';
import Button from '../components/Button';
import Row from '../components/Row';
import { useReducerContext } from '../contexts/ReducerContext';
import ACTIONS from '../constants/actions';
import { requestPasswordReset, resetPassword } from '../apis/user';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface ResetPassworPageProps {
}

const LocalPage = styled(Page)`
  > * {
    width: 500px;
  }
  
  @media ${MOBILE_DEVICE} {
    > * {
      width: 100%;
    }
  }
`;

const LocalSection = styled(Section)`
  padding: 50px 75px 67px 75px;
  
  @media ${MOBILE_DEVICE} {
    padding: 20px;
    background: transparent;
    border: none;
  }
`;

const LocalColumn = styled(Column)`
  width: 100%;
`;

const LocalTextInput = styled(TextInput)`
  width: 100%;
`;

const LocalButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  
  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`;

const InfoContainer = styled(Column)`
  gap: 10px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 20px;
  }
`

const FormContainer = styled(Column)`
  gap: 20px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 10px;
  }
`

const InputContainer = styled(Column)`
  gap: 3px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 5px;
  }
`

const ActionContainer = styled(Column)`
  align-items: center;
  gap: 20px;
  width: 100%;

  @media ${MOBILE_DEVICE} {
    gap: 10px;
  }
`;


const ResetPasswordPage: FunctionComponent<ResetPassworPageProps> = () => {
    const { dispatch } = useReducerContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [passwordConfirmationError, setPasswordConfirmationError] = useState(false);
    const [sent, setSent] = useState(false);

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        setPasswordError(false);
    };

    const handlePasswordBlur = () => {
        setPasswordError(password.length < 8);
    };

    const handlePasswordConfirmationChange = (value: string) => {
        setPasswordConfirmation(value);
        setPasswordConfirmationError(false);
    };

    const handlePasswordConfirmationBlur = () => {
        setPasswordConfirmationError(password !== passwordConfirmation);
    };

    const handleSubmit = async () => {
        if (searchParams.has('code')) {
            dispatch({
                type: ACTIONS.START_LOADING,
            });
            try {
                await resetPassword(
                    searchParams.get('code') as string,
                    password
                );
                setSent(true);
            } catch (e) {
            }
            dispatch({
                type: ACTIONS.STOP_LOADING,
            });
        }
    };

    return (
        <LocalPage>
            <LocalSection>
                <Container>
                    {sent
                        ? (
                            <>
                                <InfoContainer>
                                    <PageHeading>
                                        {t('core.password_reset_title')}
                                    </PageHeading>
                                    <Text>
                                        {t('core.password_reset_description')}
                                    </Text>
                                </InfoContainer>
                                <LocalButton onClick={() => navigate('/login')}>
                                    {t('core.go_to_login')}
                                </LocalButton>
                            </>
                        )
                        : (
                            <>
                                <InfoContainer>
                                    <PageHeading>
                                        {t('core.set_password_title')}
                                    </PageHeading>
                                    <Text>
                                        {t('core.set_password_description')}
                                    </Text>
                                </InfoContainer>

                                <FormContainer>
                                    <InputContainer>
                                        <SubCategoryHeading>
                                            {t('core.set_password')}
                                        </SubCategoryHeading>
                                        <LocalColumn>
                                            <LocalTextInput
                                                type="password"
                                                error={passwordError}
                                                value={password}
                                                onTextChange={handlePasswordChange}
                                                onBlur={handlePasswordBlur}
                                            />
                                            <Text
                                                style={{ marginTop: 4 }}
                                                error={passwordError}
                                                variant="tiny"
                                            >
                                                {t('core.password_restriction')}
                                            </Text>
                                        </LocalColumn>
                                    </InputContainer>
                                    <InputContainer>
                                        <SubCategoryHeading>
                                            {t('core.confirm_password')}
                                        </SubCategoryHeading>
                                        <LocalColumn>
                                            <LocalTextInput
                                                type="password"
                                                error={passwordConfirmationError}
                                                value={passwordConfirmation}
                                                onTextChange={handlePasswordConfirmationChange}
                                                onBlur={handlePasswordConfirmationBlur}
                                            />
                                            {passwordConfirmationError && (
                                                <Text
                                                    style={{ marginTop: 4 }}
                                                    error
                                                    variant="tiny"
                                                >
                                                    {t('core.password_mismatch')}
                                                </Text>
                                            )}
                                        </LocalColumn>
                                    </InputContainer>
                                </FormContainer>

                                <ActionContainer>
                                    <LocalButton
                                        disabled={password.length < 8 || password !== passwordConfirmation}
                                        onClick={handleSubmit}
                                    >
                                        {t('core.reset_password')}
                                    </LocalButton>
                                </ActionContainer>
                            </>
                        )
                    }
                </Container>
            </LocalSection>
        </LocalPage>
    );
};

export default ResetPasswordPage;