import { FunctionComponent } from "react";
import styled from "styled-components";
import EmployeeEmployerInfoSection from "./blocks/employmentConditions/EmployeeEmployerInfoSection";
import ContractPeriodSection from "./blocks/employmentConditions/ContractPeriodSection";
import PlaceOfEmploymentSection from "./blocks/employmentConditions/PlaceOfEmploymentSection";
import TypeOfWorkSection from "./blocks/employmentConditions/TypeOfWorkSection";
import WorkHoursSection from "./blocks/employmentConditions/WorkHoursSection";
import HolidaysSection from "./blocks/employmentConditions/HolidaysSection";
import VacationSection from "./blocks/employmentConditions/VacationSection";
import CompensationSection from "./blocks/employmentConditions/CompensationSection";
import ResignationSection from "./blocks/employmentConditions/ResignationSection";
import OtherSection from "./blocks/employmentConditions/OtherSection";
import { Column20 } from "./commonStyles";
import BasicWageSection from "./blocks/compensations/BaseWageSection";
import BenefitsSection from "./blocks/compensations/BenefitsSection";
import EstimatedPaymentSection from "./blocks/compensations/EstimatedPaymentSection";
import DeductionsSection from "./blocks/compensations/DeductionsSection";
import TakeHomeWageSection from "./blocks/compensations/TakeHomeWageSection";
import { LanguageCode } from "../../../core/enums/language";
import { EmploymentConditionTranslationHandler } from "./util";
import { TemplateSectionProps } from "./types";
import { EmploymentCondition } from "../../types";
import ContractSection from "./blocks/specifiedSkilledWorkers/ContractSection";
import SignatureSecion from "./blocks/specifiedSkilledWorkers/SignatureSection";
import { useTranslation } from "react-i18next";
import ContractConclusionSection from "./blocks/employmentConditions/ContractConclusionSection";
import { useReducerContext } from "../../../core/contexts/ReducerContext";

interface EmploymentConditionsTemplateProps {
  employmentConditions?: EmploymentCondition;
  transLanguage?: LanguageCode;
}

const FormContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px; //60px
  border-radius: 5px;
  border: 0.5px solid #e5d5d5;
  font-size: 12px;
  line-height: normal;
  font-family: "Hiragino mincho pro", "Zen old mincho", serif;

  @media print {
    visibility: visible;
    z-index: 1000;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }
`;

const FormHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 20px;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const FormHeaderTextInJapanese = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 520px;
  width: 100%;
`;

const FormHeaderTextInTransLang = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  width: 100%;
`;

const EmploymentConditionsSections = styled.div`
  margin-bottom: 40px;

  > *:last-child {
    margin-top: 40px;
  }
`;

const CompensationsSections = styled(Column20)`
  @media print {
    page-break-after: always;
  }
`;

const SpecifiedSkillWorkersSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;


const Separator = styled.hr`
  margin-top: 60px;
  margin-bottom: 60px;
  @media print {
    visibility: hidden;
  }
`;

const EmploymentConditionsTemplate: FunctionComponent<EmploymentConditionsTemplateProps> = ({
  employmentConditions: ec,  
  transLanguage
}) => {
  const { state } = useReducerContext();
  const { i18n } = useTranslation();
  const transLangCode = transLanguage || LanguageCode.English;
  const empPrefix = "proposal.employment_conditions";
  const compPrefix = "proposal.compensations";
  const sswPrefix = "proposal.specified_skill_worker";
  const tFixedWithEmpCond = i18n.getFixedT(transLangCode, "", empPrefix);
  const tFixedEmpCond = (key: string): string => tFixedWithEmpCond(key).toString();
  const tFixedWithComp = i18n.getFixedT(transLangCode, "", compPrefix);
  const tFixedComp = (key: string): string => tFixedWithComp(key).toString();
  const tFixedWIthSsw = i18n.getFixedT(transLangCode, "", sswPrefix);
  const tFixedSsw = (key: string): string => tFixedWIthSsw(key).toString();
  const transHandler = new EmploymentConditionTranslationHandler(ec?.translations, transLangCode);
  const ecProps: TemplateSectionProps = {
    employmentCondition: ec,
    ecTranslationHandler: transHandler,
    i18nFormPrefixKey: empPrefix,
    company: state.company
  }
  const compProps: TemplateSectionProps = {
    employmentCondition: ec,
    ecTranslationHandler: transHandler,
    i18nFormPrefixKey: compPrefix
  }
  const sswProps: TemplateSectionProps = {
    employmentCondition: ec,
    ecTranslationHandler: transHandler,
    i18nFormPrefixKey: sswPrefix,
    company: state.company
  }

  return (
    <FormContainer>
      <FormHeaderContainer>
        <div>
          <span>参考様式１−６号</span>
          <br />
          <span>{tFixedEmpCond("ref_format_no_1_6")}</span>
        </div>
        <FormHeader>
          <FormHeaderTextInJapanese>
            <span>雇</span>
            <span>用</span>
            <span>条</span>
            <span>件</span>
            <span>書</span>
          </FormHeaderTextInJapanese>
          <FormHeaderTextInTransLang>
            {tFixedEmpCond("main_header")}
          </FormHeaderTextInTransLang>
        </FormHeader>
      </FormHeaderContainer>
      <EmploymentConditionsSections>
        <EmployeeEmployerInfoSection {...ecProps}/>
        <ContractPeriodSection {...ecProps}/>
        <PlaceOfEmploymentSection {...ecProps}/>
        <TypeOfWorkSection {...ecProps}/>
        <WorkHoursSection {...ecProps}/>
        <HolidaysSection {...ecProps}/>
        <VacationSection {...ecProps}/>
        <CompensationSection {...ecProps}/>
        <ResignationSection {...ecProps}/>
        <OtherSection {...ecProps}/>
        <ContractConclusionSection {...ecProps}/>
      </EmploymentConditionsSections>

      <Separator />

      <FormHeaderContainer>
        <div>
          <span>参考様式第１－６号 別紙</span>
          <br />
          <span>{tFixedComp("ref_format_no_1_6")}</span>
        </div>
        <FormHeader>
          <FormHeaderTextInJapanese>
            <span>賃</span>
            <span>金</span>
            <span>の</span>
            <span>支</span>
            <span>払</span>
          </FormHeaderTextInJapanese>
          <FormHeaderTextInTransLang>
            {tFixedComp("main_header")}
          </FormHeaderTextInTransLang>
        </FormHeader>
      </FormHeaderContainer>
      <CompensationsSections>
        <BasicWageSection {...compProps}/>
        <BenefitsSection {...compProps}/>
        <EstimatedPaymentSection {...compProps}/>
        <DeductionsSection {...compProps}/>
        <TakeHomeWageSection {...compProps}/>
      </CompensationsSections>

      <Separator />

      <FormHeaderContainer>
        <div>
          <span>参考様式１− 5号</span>
          <br />
          <span>{tFixedSsw("ref_format_no_1_5")}</span>
        </div>
        <FormHeader>
          <FormHeaderTextInJapanese>
            <span>特</span>
            <span>定</span>
            <span>技</span>
            <span>能</span>
            <span>雇</span>
            <span>用</span>
            <span>契</span>
            <span>約</span>
            <span>書</span>
          </FormHeaderTextInJapanese>
          <FormHeaderTextInTransLang>
            {tFixedSsw("main_header")}
          </FormHeaderTextInTransLang>
        </FormHeader>
      </FormHeaderContainer>
      <SpecifiedSkillWorkersSections>
        <ContractSection {...sswProps}/>
        <SignatureSecion {...sswProps}/>
      </SpecifiedSkillWorkersSections>
      
      <Separator />
    </FormContainer>
  );
};

export default EmploymentConditionsTemplate;
