import { FunctionComponent, useState } from "react";
import styled from "styled-components";
import Section from "../../../components/Section";
import { ReactComponent as BackIcon } from "../../../../assets/icon-back.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/icon-info-orange.svg";
import { ReactComponent as CrossIcon } from "../../../../assets/icon-cross.svg";
import Text from "../../../components/Text";
import Row from "../../../components/Row";
import { useNavigate } from "react-router-dom";
import Column from "../../../components/Column";
import ToggleButton from "../../../components/ToggleButton";
import ButtonGroup from "../../../components/ButtonGroup";
import Radio from "../../../components/Radio";
import Field from "../../../components/Field";
import UploadButton from "../../../components/UploadButton";
import EmploymentOfferTemplate from "../../../components/EmploymentOfferTemplate";
import { useReducerContext } from "../../../contexts/ReducerContext";
import { convertBytesToMB } from "../../../utils";
import { deleteCompanySealFile, updateCompany, updateCompanySealFile, uploadCompanySealFile } from "../../../apis/company";
import ACTIONS from "../../../constants/actions";

interface EmploymentOfferProps {}

enum Signee {
  Self = "self",
  Tokuty = "tokuty"
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 800px;
  gap: 20px;
`;

const HeadingContainer = styled(Row)`
  align-items: center;
  gap: 20px;
`;

const Heading = styled(Text)`
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
`;

const LocalSection = styled(Section)`
  padding: 30px 30px 60px 30px;
  flex-direction: column;
  gap: 30px;
`;

const NoteContainer = styled(Column)`
  background: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
  gap: 20px;
`;

const NoteHeadingContainer = styled(Row)`
  align-items: center;
  gap: 10px;
`;

const NoteDescriptionContainer = styled(Column)`
  gap: 10px;
`;

const SectionHeading = styled(Text)`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
`;

const DefaultSettingContainer = styled(Column)`
  gap: 20px;
`;

const SignatureContainer = styled(Column)`
  gap: 10px;
`;

const StampContainer = styled(Column)`
  gap: 10px;
`;

const FileAttrContainer = styled(Row)`
  gap: 10px;
  align-items: center;
`;

const FileNameAnchor = styled.a`
  color: inherit;
  text-decoration: none;
`;

const FileNameText = styled(Text)`
  color: #017698;
`;

const FileSizeText = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

const UploadItemsContainer = styled(Column)`
  gap: 20px;
`;

const DeleteIcon = styled(CrossIcon)`
  border-radius: 5px;
  cursor: pointer;
  path {
   fill: #5f6368; 
  }

  &:hover {
    background-color: #FFEADB;
    path {
      fill: #FF730D;
    }
  }
`;

const PreviewContainer = styled(Column)`
  gap: 10px;
`;

const PreviewHeading = styled(Text)`
  font-weight: 500;
  font-size: 26px;
  line-height: 38px;
`;

const EmploymentOfferContainer = styled.div`
  background-color: #EDEDED;
  padding: 30px;
`;

const Link = styled.span`
  color: #017698;
  text-decoration: none;
  cursor: pointer;
`;

/* NOTE: This component is for Employer use only and doesn't need to show in other langauges but Japanese */
const EmploymentOffer: FunctionComponent<EmploymentOfferProps> = () => {
  const { state, dispatch } = useReducerContext();
  const { company, isUploading } = state;
  const navigate = useNavigate();
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB;
  const [sealFileId, setSealFileId] = useState(company?.companySealFile?.id ?? "");
  const [sealFileName, setSealFileName] = useState(company?.companySealFile?.filename ?? "");
  const [sealFileSize, setSealFileSize] = useState(company?.companySealFile?.size ?? 0);
  const [sealUrl, setSealUrl] = useState(company?.companySealFile?.url ?? "");
  const [isSignedBySelf, setIsSignedBySelf] = useState(!!company?.useOwnSignatureOnEmploymentOffer);

  const companyName = company!.name;
  const address = [
    company!.prefecture,
    company!.cityWard,
    company!.addressNumber,
    company!.buildingName ?? ""
  ].join(" ");

  const onDefaultSettingChange = async (value: boolean) => {
    try {
      const updatedCompanyData = await updateCompany(company!.id, {
        autoSendEmploymentOffer: value,
      });

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: updatedCompanyData
        },
      });
    } catch {
    }
  };

  const onSigneeSettingChange = async (value: Signee) => {
    const newValue = value === Signee.Self;

    setIsSignedBySelf(newValue);
     
    try {
      const updatedCompanyData = await updateCompany(company!.id, {
        useOwnSignatureOnEmploymentOffer: newValue,
      });

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: updatedCompanyData
        },
      });
    } catch {
      setIsSignedBySelf(!newValue);
    } 

  }

  const onSealFileChange = async (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      //TODO:
      //Update the snackbar and the style of this message per the design
      //https://www.figma.com/file/UwZXypeCGS6TGrveckqOOU/tokuty?type=design&node-id=9927-84374&mode=design&t=7FTiQFTH1kcjIZyY-4
      dispatch({ type: ACTIONS.SET_PROMPT, payload: {
          type: 'warning',
          message: "ファイルのアップロードに失敗しました。５MB以下のファイルを選択してください"
        },
      });
      return;
    }
    
    try {
      dispatch({ type: ACTIONS.START_UPLOADING });
      const companySealFile = sealFileId
        ? await updateCompanySealFile(sealFileId, file)
        : await uploadCompanySealFile(company!.id, file);

      setSealFileId(companySealFile.id);
      setSealFileName(companySealFile.filename);
      setSealFileSize(companySealFile.size);
      setSealUrl(companySealFile.url);

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: {
            ...company,
            companySealFile: companySealFile
          } 
        },
      });

    } catch {
    } finally {
      dispatch({ type: ACTIONS.STOP_UPLOADING });
    }
  };

  const onDeleteClick = async () => {
    try {
      await deleteCompanySealFile(sealFileId);
      setSealFileId("");
      setSealFileName("");
      setSealFileSize(0);
      setSealUrl("");

      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {            
          company: {
            ...company,
            companySealFile: undefined
          } 
        },
      });
    } catch {
    }
  }


  return (
    <Container>
      <HeadingContainer>
        <BackIcon onClick={() => navigate("/settings/documents")} style={{ cursor: "pointer" }} />
        <Heading>内定証明書</Heading>
      </HeadingContainer>
      <LocalSection>
        <NoteContainer>
          <NoteHeadingContainer>
            <InfoIcon />
            <Text>
              <b>内容証明書の自動発行機能とは</b>
            </Text>
          </NoteHeadingContainer>
          <NoteDescriptionContainer>
            <Text>
              候補者を採用した際に内定証明書を自動的に作成し、候補者の登録メールアドレスへ送付することができます。
            </Text>
            <Text>
              候補者や自社の名前等を印字できるほか、画像データがあれば、社印を印字することも可能です。
            </Text>
          </NoteDescriptionContainer>
        </NoteContainer>

        <DefaultSettingContainer>
          <SectionHeading>デフォルトの利用設定</SectionHeading>
          <ToggleButton toggled={!!company?.autoSendEmploymentOffer} onToggle={onDefaultSettingChange} />
        </DefaultSettingContainer>

        <SignatureContainer>
          <SectionHeading>署名</SectionHeading>
          <Text>
            署名者を選ぶことができます。自社を選んだ場合は捺印画像をアップロードしてください
          </Text>
          <Field>
            <ButtonGroup style={{ marginTop: "8px" }}>
              <Radio
                checked={!isSignedBySelf}
                value={Signee.Tokuty}
                onValueChange={onSigneeSettingChange}
              >
                トクティー株式会社
              </Radio>
              <Radio
                checked={isSignedBySelf}
                value={Signee.Self}
                onValueChange={onSigneeSettingChange}
              >
                自社
              </Radio>
            </ButtonGroup>
          </Field>
        </SignatureContainer>

        {isSignedBySelf && (
          <StampContainer>
            <SectionHeading>捺印</SectionHeading>
            <Text>最大5MBのJPG又はPNGファイルのみ（推奨の大きさ：360 x 360ピクセル）</Text>
            <UploadItemsContainer>
              <UploadButton
                buttonText="ファイルを選択"
                onFileChange={onSealFileChange}
                accept=".jpg, .jpeg, .png"
              />
              { isUploading && 
                <Text>アップロード中...</Text> 
              }
              { !isUploading && sealFileId && 
                <FileAttrContainer>
                  <FileNameText>
                    <FileNameAnchor 
                      href={sealUrl} 
                      target="_blank" 
                      rel="noreferrer"
                    >
                      {sealFileName}
                    </FileNameAnchor>
                  </FileNameText>
                  <FileSizeText>{convertBytesToMB(sealFileSize)}MB</FileSizeText>
                  <DeleteIcon onClick={onDeleteClick} />
                </FileAttrContainer>
              }
            </UploadItemsContainer>
          </StampContainer>
        )}

        <PreviewContainer>
          <PreviewHeading>プレビュー</PreviewHeading>
          <Text>
            自社の情報は<Link onClick={() => navigate("/settings/organization")}>組織設定</Link>から引用します
          </Text>
          <EmploymentOfferContainer>
            <div style={{ boxShadow: '1px 2px 6px 0px #00000033'}}>
              <EmploymentOfferTemplate 
                isUsingOwnSignature={isSignedBySelf} 
                companyName={ isSignedBySelf ? companyName : "" }
                address={ isSignedBySelf ? address : "" }
                sealUrl={sealUrl}              
              />
            </div>
          </EmploymentOfferContainer>
        </PreviewContainer>
      </LocalSection>
    </Container>
  );
};

export default EmploymentOffer;
